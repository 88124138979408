import React, { useEffect, useState } from 'react';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Subtitle } from 'views/offer/components/Subtitle';
import { sendVoucher } from 'api/email';
import { LINKS } from 'constants/links';
import { validateEmail } from 'utils/utils';

import SuccessModal from './components/success-modal';

export const VoucherView = ({ email }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const sendVoucherEmail = async () => {
      if (email && validateEmail(email)) {
        const res = await sendVoucher({ email, isTermsAccepted: true });

        if (res.error) {
          return;
        }
        setIsModalOpen(true);
      }
    };

    sendVoucherEmail();
  }, [email]);

  return (
    <div>
      {isModalOpen && (
        <SuccessModal email={email} closeModal={() => setIsModalOpen(false)} />
      )}
      <Header breadcrumbsLinks={[LINKS.LEXICON]}>
        REGULAMIN AKCJI PROMOCYJNEJ: "100 ZŁ DO WYKORZYSTANIA NA ZABIEG ZA ZAPIS NA NEWSLETTER"
      </Header>
      <Content>
        <Subtitle>1. ORGANIZATOR AKCJI PROMOCYJNEJ</Subtitle>
        <Paragraph>
          Organizatorem akcji jest IDEALE Sp. z o.o., (dalej: "Organizator") z siedzibą w Warszawie, ul. Sokołowska 9, lok. U-12, 01-142.
        </Paragraph>

        <Subtitle>
          2. ZASADY AKCJI PROMOCYJNEJ
        </Subtitle>
        <List>
          <ListItem>
            Akcja polega na udzieleniu przez Organizatora jednorazowej zniżki w wysokości 100 zł na zabieg z zakresu medycyny estetycznej. Zniżka przysługuje wyłącznie osobom, które po raz pierwszy zapiszą się do Newslettera IDEALE Medycyna Estetyczna i potwierdzą swoją subskrypcję, klikając w link aktywacyjny przesłany na podany adres e-mail.
          </ListItem>
          <ListItem>
            Zniżka komunikowana jest poprzez stronę internetową:
            <a href="https://ideale.pl">https://ideale.pl</a>
          </ListItem>
          <ListItem>
            Z vouchera można skorzystać wyłącznie w siedzibie Organizatora przy ul. Sokołowska 9 (Centrum Medyczne Medify.me).
          </ListItem>
          <ListItem>
            Oferowana zniżka nie podlega wymianie na środki pieniężne ani nie łączy się z innymi zniżkami oferowanymi przez Organizatora (np. karta lojalnościowa).
          </ListItem>
          <ListItem>
            Voucher można wykorzystać tylko na zabiegi z zakresu medycyny estetycznej. Vouchera NIE można wykorzystać do zakupu zabiegów z zakresu dermatochirurgii, laseroterapii oraz skleroterapii.
          </ListItem>
        </List>

        <Subtitle>
          3. OSOBY UPRAWNIONE DO SKORZYSTANIA Z OFERTY ZNIŻKOWEJ
        </Subtitle>
        <Paragraph>
          Z oferty zniżkowej może skorzystać osoba, która pierwszy raz zapisze się do Newslettera IDEALE Medycyna Estetyczna oraz otrzyma kupon o wartości 100 zł na podany podczas rejestracji adres e-mail.
        </Paragraph>

        <Subtitle>4. CZAS TRWANIA AKCJI I OKRES OBOWIĄZYWANIA ZNIŻEK</Subtitle>
        <List>
          <ListItem>
            Akcja promocyjna trwa od dnia 01.10.2024 do odwołania lub do wyczerpania limitu dostępnych kuponów.
          </ListItem>
          <ListItem>
            Voucher można wykorzystać przez 3 miesiące od daty otrzymania vouchera.
          </ListItem>
        </List>

        <Subtitle>5. POSTANOWIENIA KOŃCOWE</Subtitle>
        <List>
          <ListItem>
            Zniżki, o których mowa w niniejszym regulaminie, nie będą łączone z innymi zniżkami oferowanymi przez Organizatora w okresie trwania Akcji oraz innymi zniżkami, jakie posiada pacjent. Klient będzie miał prawo dokonać wyboru z której zniżki lub promocji w dniu konsultacji korzysta.
          </ListItem>
          <ListItem>
            Organizator zastrzega sobie prawo do wprowadzania zmian w regulaminie w każdym momencie, bez wcześniejszego uprzedzenia. O każdej zmianie regulaminu Organizator poinformuje na stronie internetowej https://ideale.pl.
          </ListItem>
          <ListItem>
            Regulamin obowiązuje od dnia opublikowania.
          </ListItem>
          <ListItem>
            Dane osobowe podane przez uczestników akcji będą przetwarzane zgodnie z przepisami prawa ochrony danych osobowych w celu realizacji niniejszej akcji promocyjnej oraz wysyłki newslettera. Szczegółowe informacje na temat przetwarzania danych osobowych znajdują się w Polityce Prywatności na stronie https://ideale.pl.
          </ListItem>
        </List>
      </Content>
    </div>
  );
};